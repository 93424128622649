import React, { useState } from "react";
import axios from "axios";
import { AxiosError } from "axios";

const Home = () =>  {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('just try once');
    const [action, setAction] = useState('Subscribe');
    const [header, setHeader] = useState('Subscribe to our newsletter!');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email === '') {
            setMessage('just try once');
        }
        else if (!/\S+@\S+\.\S+/.test(email)) {
            setMessage('Please enter a valid email address');
        } else {
            const newsletter = {email};
            if(action === 'Subscribe') {
                const headers = { 
                    'Content-Type': 'application/json'
                };
                axios.post('https://api.walletr.ee/api/v1/newsletters/subscribe', JSON.stringify(newsletter), { headers })
                    .then(response => {
                        setMessage( response.data.message );
                        setEmail('');
                    }, error => {
                        setMessage( 'Error!!!' );
                    });
            } else if ( action === 'Unsubscribe') {
                const headers = { 
                    'Content-Type': 'application/json'
                };
                axios.post('https://api.walletr.ee/api/v1/newsletters/unsubscribe', JSON.stringify(newsletter), { headers })
                    .then(response => {
                        setMessage( response.data.message );
                        setEmail('');
                    }, error => {
                        setMessage( 'Error!!!' )
                    });
            } else {
                const headers = { 
                    'Content-Type': 'application/json'
                };
                axios.post('https://api.walletr.ee/api/v1/newsletters/check', JSON.stringify(newsletter), { headers: headers })
                    .then(response => {
                        setMessage( response.data.message )
                    }, error => {
                        setMessage( 'Error!!!' );
                        console.log(error);
                    });
            }
        }
    }
    const changeAction = (text) => {
        setAction(text);
        setMessage('just try once');
        if(text === 'Subscribe') {
            setHeader('Subscribe to our newsletter!');
        } else if( text === 'Unsubscribe' ) {
            setHeader('Unsubscribe to our newsletter :(')
        } else {
            setHeader('Check your subscription status')
        }
    }
    
    function handleInput(event) {
        setEmail(event.target.value);
    }

    return (
        <>
            <h2>{header}</h2>
            <form onSubmit={handleSubmit}>
                <input
                // type="email"
                placeholder="Enter your email address here"
                value={email}
                onChange={handleInput}
                style={{
                    padding: "10px",
                    fontSize: "16px",
                    border: "2px solid #FFDB00",
                    borderRadius: "5px",
                    fontWeight: "bold"
                }}
                />
                <button
                type="submit"
                style={{
                    margin: "10px",
                    padding: "10px",
                    backgroundColor: "#000",
                    border: "none",
                    color: "#FFDB00",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    cursor: "pointer",
                }}
                >
                {action}
                </button>
            </form>
            <p>{message}</p>
            <div className="action">
                <button 
                    onClick={() => changeAction('Subscribe')}
                    style={{
                        margin: "10px",
                        padding: "10px",
                        backgroundColor: "#ddd",
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        cursor: "pointer"}}>
                    Subscribe
                </button>
                <button 
                    onClick={() => changeAction('Unsubscribe')}
                    style={{
                        margin: "10px",
                        padding: "10px",
                        backgroundColor: "#ddd",
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        cursor: "pointer"}}>
                    Unsubscribe
                </button>
                <button 
                    onClick={() => changeAction('Check')}
                    style={{
                        margin: "10px",
                        padding: "10px",
                        backgroundColor: "#ddd",
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        cursor: "pointer"}}>
                    Check
                </button>
            </div>
        </>
    )    
    
}


export default Home;