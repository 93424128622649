import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <header>
            <div className="container">
                <Link to="/">
                    <h1>walletr.ee</h1>
                </Link><span style = {{display: "inline-block", marginInlineStart: "10px"}}>coming soon</span>
            </div>
        </header>
    )
}

export default Header;