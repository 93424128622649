import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

//pages and components
import Home from './pages/Home';
import Header from './components/Header'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <Header />
          <Routes>
            <Route
              path='/'
              element={<Home />}
              />
              <Route path="*" element={<Navigate to='/' replace />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
